.wef-widget-column-header-container {
  background-color: $lighter-blue;

  .wef-widget-column-header {
    padding-left: 0.875rem;
    padding-right: 0.875rem;

    .flex-column-header {

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @media (max-width: 480px) {
        overflow-x: scroll;
      }

      >.col {
        max-width: 120px;
        width: 100%;
        background-color: $blue;
        padding: 0.375rem 0.75rem;

        @media (max-width: 480px) {
          min-width: 120px;
          margin-right: 0.5rem;
        }
      }
    }

    .column-header-title,
    .column-header-text {
      margin: 0;
    }
  }
}