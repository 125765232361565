.wef-structural-indicators {
  padding: 1rem 0.875rem;
  background-color: $lighter-blue;

  .wef-structural-indicators-legend {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .legend-text {
      // margin-left: 1rem;
    }
  }

  .wef-structural-indicators-content {
    .flex-structural-indicators {
      display: flex;
      justify-content: space-between;

      @media (max-width: 480px) {
        overflow-x: scroll;
      }

      > .col {
        max-width: 120px;
        width: 100%;

        @media (max-width: 480px) {
          min-width: 120px;
          margin-right: 0.5rem;
        }
      }
    }
  }
} 