html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

#wef-widget-app {
  max-width: 660px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

header {
  background-color: $white;
  z-index: 100;
}

.hidden {
  display: none;
}

#header {
  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 660px;
  }
}