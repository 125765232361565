.wef-widget-controls {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  padding-left: 4.75rem;
  padding-right: 4.75rem;

  @media (max-width: 480px) {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  > div:nth-of-type(6),
  > div:nth-of-type(9) {
    margin-top: 2rem;

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  .flex-wef-widget-controls {
    display: flex;
    align-items: center;

    .col-first {
      text-align: right;
      padding-right: 0.875rem;
      max-width: 280px;
      width: 100%;

      @media (max-width: 480px) {
        max-width: 140px;
      }
    }

    .col-second {

    }

    .col-third {
      flex-grow: 1;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      text-align: center;
    }

    .col-fourth {}

    .col-height {
      height: 24px;
    }
  }

  .controls-title {
    margin: 0;
  }

  .controls-text {
    margin: 0;
  }

  h4:not(:first-of-type) {
    margin-top: 1rem;
  }
}