.wef-geographic-concentration {
  padding: 1rem 0.875rem;
  background-color: $lighter-blue;

  .wef-geographic-concentration-legend {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .legend-text {
      margin-left: 1rem;
    }
  }

  .wef-geographic-concentration-content {
    position: relative;

    .table-legend {
      display: flex;
      flex-direction: column;
      position: absolute;
      left: -0.75rem;
      top: 1.625rem;

      > span {
        padding: 0.375rem 0;
      }
    }



    .flex-geographic-concentration {
      display: flex;
      justify-content: space-between;

      @media (max-width: 480px) {
        overflow-x: scroll;
      }

      > .col {
        max-width: 120px;
        width: 100%;

        @media (max-width: 480px) {
          min-width: 120px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}