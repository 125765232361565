.table-chart {

  .table-chart-header {

    .flex-table-chart {
      display: flex;
      align-items: center;

      .col {
        width: 100%;
        text-align: center;
      }

      .component-title {
        margin: 0;
      }
    }
  }

  .table-chart-body {
    background-color: $white;

    > table {
      width: 100%;
      
      td {
        width: 50%;
        text-align: center;
        transition: background-color 0.2s ease;
      }
    }

  }
}