.section-navigation {
  background-color: $lighter-blue;

  .flex-section-navigation {
    padding: 1rem 0;
    margin: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 480px) {
      margin: 0 1rem;
    }

    > div:first-of-type {
      display: flex;
      align-items: center;

      span {
        margin-left: 1rem;
      }
    }
  }

  &.border-bottom {
    .flex-section-navigation {
      border-bottom: 1px solid $blue;
    }
  }

  .section-navigation-title {
    margin: 0;
  }
}