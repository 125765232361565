.bar-chart {

  .bar-chart-header {

    .flex-bar-chart {
      display: flex;
      align-items: center;

      .col {
        width: 100%;
        text-align: center;
      }

      .component-title {
        margin: 0;
      }
    }
  }

  .bar-chart-body {
    background-color: $white;

    .flex-content {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      height: 80px;
      padding-top: 10px;

      > .col {
        width: 24px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      > .col-left {
        .revenue {
          transition: height 0.2s ease;
          background-color: $purple;
        }
      }

      > .col-right {
        .revenue {
          transition: height 0.2s ease;
          background-color: $dark-pink;
        }
      }
    }

    .flex-footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}