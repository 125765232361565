.icon {
  display: inline-block;
  background-size: cover;
}

.icon-chevron-top {
  background-image: url(../front-end_assets/chevron_hide.svg);
  width: 24px;
  height: 24px;
}

.icon-chevron-bottom {
  background-image: url(../front-end_assets/chevron_show.svg);
  width: 24px;
  height: 24px;
}

.icon-tooltip {
  background-image: url(../front-end_assets/tooltip.svg);
  width: 24px;
  height: 24px;

  &:hover {
    background-image: url(../front-end_assets/tooltip_hover.svg);
  }
}