@font-face {
  font-family: 'HelveticaNeueBold';
  src: url('../fonts/HelveticaNeueLTCom-Md.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueRegular';
  src: url('../fonts/HelveticaNeueLTCom-Roman.ttf') format('truetype');
}

@font-face {
  font-family: 'HelveticaNeueLight';
  src: url('../fonts/HelveticaNeueLTCom-Lt.ttf') format('truetype');
}

html {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif; 
  color: $black;
  font-size: 16px;
  font-weight: 400;
  line-height: 2;
  font-family: 'HelveticaNeueRegular';
}

.font-light {
  font-weight: 300;
  font-family: 'HelveticaNeueLight';
}

.font-regular {
  font-weight: 400;
  font-family: 'HelveticaNeueLight';
}

.font-bold {
  font-weight: 700;
  font-family: 'HelveticaNeueBold';
}

.btn-header-text {
  @extend .font-bold;
  color: $white;
  font-size: 1.125rem;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
}

.header-content-title {
  color: $white;
  font-size: 1.25rem;
  letter-spacing: 1.11px;

  @media (max-width: 480px) {
    font-size: 1.125rem;
  }
}

.header-content-text {
  color: $white;
  font-size: 0.875rem;
  letter-spacing: 0.78px;

  @media (max-width: 480px) {
    font-size: 0.75rem;
  }
}

.section-navigation-title {
  @extend .font-light;
  font-size: 1.25rem;
  color: $blue;

  @media (max-width: 480px) {
    font-size: 1.125rem;
  }

  &.arrow {
    @extend .font-regular;
    text-transform: uppercase;
  }
}

.controls-title {
  font-size: 0.75rem;
  color: $blue;
  letter-spacing: 0.67px;

  &.bold {
    text-transform: uppercase;
    @extend .font-bold;
  }
}

.controls-text {
  font-size: 0.875rem;
  letter-spacing: 0.78px;
}

.column-header-title {
  text-transform: uppercase;
  @extend .font-bold;
  font-size: 0.625rem;
  letter-spacing: 0.56px;
  color: $white;
}

.column-header-text {
  font-size: 0.875rem;
  color: $white;
  letter-spacing: 0.78px;
}

.legend-text {
  @extend .font-bold;
  font-size: 0.75rem;
  color: $blue;

  &.uppercase {
    text-transform: uppercase;
  }

  span {
    text-transform: lowercase;
  }
}

.component-title {
  color: $blue;
  font-size-adjust: 0.625rem;
  letter-spacing: 0.56px;
}

.component-value {
  font-size: 0.75rem;
  letter-spacing: 0.67px;
}

.footer-text {
  color: $blue;
  font-size: 0.75rem;
  letter-spacing: 0.67px;
}

.section-subtitle {
  font-size: 0.875rem;
  letter-spacing: 0.78px;
  color: $blue;
}