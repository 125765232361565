.wef-widget-header {
  background-color: $blue;

  .header-navigation {
    border-bottom: 1px solid rgba($white, 0.3);

    .flex-header {
      display: flex;
      justify-content: center;
      padding-left: 2rem;
      padding-right: 2rem;

      @media (max-width: 480px) {
        padding-left: 1rem;
        padding-right: 1rem;
      }

      > .col {
        width: 100%;
      }
    }
  }

  .header-content {
    padding: 2rem;

    @media (max-width: 480px) {
      padding-left: 1rem;
      padding-right: 1rem;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }

    .header-content-title,
    .header-content-text {
      margin: 0;
    }
  }
}