.wef-value-pool {
  padding: 1rem 0.875rem;
  background-color: $lighter-blue;

  .wef-value-legend {
    .flex-legend {
      padding: 1.5rem 0;
      display: flex;
      justify-content: flex-end;

      > div:first-of-type {
        margin-right: 1rem;
      }

      > .col {
        display: flex;
        align-items: center;

        .flex-inner {
          display: flex;
          flex-direction: column;
          padding-left: 1rem;

          > span {
            margin: 0.05rem 0;
          }
        }
      }
    }

    .legend-text {
      margin: 0;
    }
  }

  .wef-value-content {
    .flex-flex-value-pool {
      display: flex;
      justify-content: space-between;

      @media (max-width: 480px) {
        overflow-x: scroll;
      }

      > .col {
        max-width: 120px;
        width: 100%;

        @media (max-width: 480px) {
          min-width: 120px;
          margin-right: 0.5rem;
        }
      }
    }
  }
}