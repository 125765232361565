.btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  outline: none;
  padding: 0;

  &:focus {
    outline: 0;
  }
}

.btn-transparent {
  background-color: transparent;
  border: none;
}

.btn-header {
  width: 100%;
  background-color: transparent;
  border: none;
  height: 60px;
  border-bottom: 4px solid transparent;
  
  &.active {
    border-bottom: 4px solid $white;
  }
}

.btn-flex-icon {
  display: flex;
  align-items: center;
}