.tag {
  display: inline-block;
}

.tag-purple {
  background-color: $purple;
  width: 25px;
  height: 12px;
}

.tag-light-purple {
  background-color: $light-purple;
  width: 25px;
  height: 12px;
}

.tag-pink {
  background-color: $pink;
  width: 25px;
  height: 12px;
}

.tag-dark-pink {
  background-color: $dark-pink;
  width: 25px;
  height: 12px;
}