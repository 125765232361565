.double-bar-chart {

  .double-bar-chart-header {

    .flex-double-bar-chart {
      display: flex;
      align-items: center;

      .col {
        width: 100%;
        text-align: center;
      }

      .component-title {
        margin: 0;
      }
    }
  }

  .double-bar-chart-body {
    background-color: $white;

    .flex-header {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0.5rem 0;
    }

    .flex-content {
      display: flex;
      justify-content: space-around;
      align-items: flex-end;
      height: 80px;

      > .col {
        width: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }

      > .col-left {
        height: 100%;
        
        .revenue {
          transition: height 0.2s ease;
          position: relative;
          background-color: $light-purple;

          .profitability {
            transition: height 0.2s ease;
            background-color: $purple;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }

      > .col-right {
        height: 100%;
        
        .revenue {
          transition: height 0.2s ease;
          position: relative;
          background-color: $pink;

          .profitability {
            transition: height 0.2s ease;
            background-color: $dark-pink;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
          }
        }
      }
    }

    .flex-footer {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
  }
}